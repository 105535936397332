import {
  ALERT_MESSAGE_SUCCESS,
  ALERT_TYPE_SUCCESS,
  ALERT_PREVENT_DUPLICATES,
  ALERT_AUTOHIDE_DURATION,
  ALERT_MESSAGE_WARNING_INVALID_DATA,
  ALERT_TYPE_WARNING,
  ALERT_MESSAGE_INFO_START_DELIVERY,
  ALERT_TYPE_INFO,
  ALERT_MESSAGE_WARNING_CONSUMER_DUPLICATE,
  ALERT_MESSAGE_WARNING_NOT_EXIST_CODES,
  ALERT_MESSAGE_SUCCESS_GET_EXISTING_CODES,
} from './alertMessages';

export const SUCCESS_CODES_REQUEST_SNACK = {
  message: ALERT_MESSAGE_SUCCESS,
  options: { variant: ALERT_TYPE_SUCCESS, preventDuplicate: ALERT_PREVENT_DUPLICATES, autoHideDuration: ALERT_AUTOHIDE_DURATION },
};

export const SUCCESS_GET_EXISTING_CODES_REQUEST_SNACK = {
  message: ALERT_MESSAGE_SUCCESS_GET_EXISTING_CODES,
  options: {
    variant: ALERT_TYPE_SUCCESS,
    preventDuplicate: ALERT_PREVENT_DUPLICATES,
    autoHideDuration: ALERT_AUTOHIDE_DURATION,
  },
};

export const WARNING_INVALID_DATA_SNACK = {
  message: ALERT_MESSAGE_WARNING_INVALID_DATA,
  options: { variant: ALERT_TYPE_WARNING, preventDuplicate: ALERT_PREVENT_DUPLICATES, autoHideDuration: ALERT_AUTOHIDE_DURATION },
};

export const WARNING_DUPLICATES_SNACK = {
  message: ALERT_MESSAGE_WARNING_CONSUMER_DUPLICATE,
  options: { variant: ALERT_TYPE_WARNING, preventDuplicate: ALERT_PREVENT_DUPLICATES, autoHideDuration: ALERT_AUTOHIDE_DURATION },
};

export const WARNING_NOT_EXISTING_CODES_SNACK = {
  message: ALERT_MESSAGE_WARNING_NOT_EXIST_CODES,
  options: { variant: ALERT_TYPE_WARNING, preventDuplicate: ALERT_PREVENT_DUPLICATES, autoHideDuration: ALERT_AUTOHIDE_DURATION },
};

export const INFO_START_DELIVERY_SNACK = {
  message: ALERT_MESSAGE_INFO_START_DELIVERY,
  options: {
    variant: ALERT_TYPE_INFO,
    preventDuplicate: ALERT_PREVENT_DUPLICATES,
    autoHideDuration: ALERT_AUTOHIDE_DURATION,
  },
};
