import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiConfig } from 'src/config';
import { routes } from 'src/constants/routes';
import { setLoading, setError } from 'src/store/slices/events';
import { Account } from 'src/types/accounts';
import { setAuthHeaders } from 'src/utils';

const ReducerName = 'accounts';

export const fetchAccounts = createAsyncThunk(`${ReducerName}/fetchAccounts`, async (_, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setLoading(true));
    const response = await fetch(`${apiConfig.API_URL}/customer-config`, {
      method: 'GET',
      headers: setAuthHeaders(),
    });
    if (response.status === 200) {
      const data = (await response.json()) as Account[];
      const filteredData = data.filter((account) => account.invitationCode.required);

      if (filteredData.length === 0) {
        dispatch(setError({ path: routes.ERROR_NOT_AVAILABLE_LEASING_COMPANIES, status: response.status }));
      }
      return filteredData;
    } else {
      dispatch(setError({ status: response.status, title: response.statusText }));
    }
    return rejectWithValue(response.status);
  } catch (e) {
    dispatch(setError({ status: 503, message: (e as TypeError).message }));
    return rejectWithValue(e);
  } finally {
    dispatch(setLoading(false));
  }
});

export interface AccountsState {
  accounts: Account[];
}

const initialState: AccountsState = {
  accounts: [],
};

export const accountsSlice = createSlice({
  name: ReducerName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.fulfilled, (state: AccountsState, action: { payload: Account[] }) => {
      state.accounts = action.payload;
    });
  },
});
