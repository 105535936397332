import { apiConfig } from './api';

export const authConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID || '',
  location: window.location,
  provider: process.env.REACT_APP_PROVIDER || '',
  redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin + '/login/callback',
  scopes: process.env.REACT_APP_SCOPES ? process.env.REACT_APP_SCOPES.split(' ') : [],
  tokenEndpoint: apiConfig.API_URL + '/token',
  extraParams: {
    adaptor: process.env.REACT_APP_ADAPTOR || '',
  },
};
