import { InputBase } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import React from 'react';

type Props = {
  value?: string;
  id: number | string;
  field: string;
  error?: string;
};

const TableEditCell = ({ id, value, field }: Props): JSX.Element => {
  const apiRef = useGridApiContext();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    void apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
      <InputBase value={value} onChange={handleValueChange} autoFocus />
    </div>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.value === nextProps.value;
};

export default React.memo(TableEditCell, areEqual);
