import { Box, TablePagination, Typography, FormControlLabel, Switch, Tooltip } from '@mui/material';
import {
  DataGridProps,
  GridCellEditCommitParams,
  GridCellParams,
  GridColumns,
  gridPageSelector,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { FormikErrors, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import './ConsumersDataTable.css';
import * as yup from 'yup';

import { COLUMNS_CONSUMER_WITH_DATA } from 'src/constants/columnsOfTable';
import { ConsumerCode, InitialValuesFormik } from 'src/types/code';

import { GridTable } from '../GridTable';
import TableCell from './TableCell';
import TableEditCell from './TableEditCell';
import TableEditCellPhone from './TableEditCellPhone';

const phoneRegExp = /^\(?[0-9]{3}\)?\s?[2-9][0-9]{2}-?[0-9]{4}$/;
const incorrectPhoneFormatMessage = 'Must be 10-digit combination where 1st and 4th characters are digits in the range 2-9';
const incorrectPhoneAreaCodeMessage = 'Area code is not valid US area code';

export const consumersCodesValidationSchema = yup.array().of(
  yup.object().shape(
    {
      consumerId: yup.string().required('Unique key is required'),
      email: yup
        .string()
        .email('Email must be in format john.doe@email.com')
        .when('phone', {
          is: (phone: string) => !phone || phone.length === 0,
          then: yup.string().email().required('Email or phone is required'),
          otherwise: yup.string(),
        }),
      phone: yup.string().when('email', {
        is: (email: string) => !email || email.length === 0,
        then: yup
          .string()
          .matches(phoneRegExp, incorrectPhoneFormatMessage)
          .required('Email or phone is required')
          .test('is-valid-area-code', incorrectPhoneAreaCodeMessage, (value) => (value ? parsePhoneNumber(value, 'US').isValid() : false)),
        otherwise: yup
          .string()
          .matches(phoneRegExp, incorrectPhoneFormatMessage)
          .notRequired()
          .test('is-valid-area-code', incorrectPhoneAreaCodeMessage, (value) => (value ? parsePhoneNumber(value, 'US').isValid() : true)),
      }),
    },
    [['email', 'phone']],
  ),
);

type Props = {
  formik: FormikProps<InitialValuesFormik>;
  consumers: ConsumerCode[];
};

type FooterProps = {
  count: number;
  errors: number;
  checked: boolean;
  filterCounsumers: () => void;
};

export function CustomFooterComponent(props: FooterProps) {
  const { errors, count, filterCounsumers, checked } = props;
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const rowsPerPage =
    gridPaginatedVisibleSortedGridRowIdsSelector(apiRef).length < 20 ? 20 : gridPaginatedVisibleSortedGridRowIdsSelector(apiRef).length;

  return (
    <Box data-testid="table-footer" sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <TablePagination
        data-testid="table-pagination"
        component="div"
        count={count}
        page={page}
        onPageChange={(event: unknown, page: number) => apiRef.current.setPage(page)}
        rowsPerPageOptions={[20, 50, 100]}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          apiRef.current.setPageSize(Number(event.target?.value));
        }}
      />
      <Typography data-testid="rows-with-errors" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Rows with errors: {errors}
      </Typography>
      {errors || checked ? (
        <FormControlLabel
          data-testid="errors-label"
          control={<Switch data-testid="switch-error" onChange={filterCounsumers} />}
          labelPlacement="start"
          label="Show only errors"
        />
      ) : (
        <Tooltip
          data-testid="tooltip-no-errors"
          title="There are no errors in the table"
          children={
            <FormControlLabel
              data-testid="errors-label-disabled"
              control={<Switch data-testid="switch-error-disabled" disabled />}
              labelPlacement="start"
              label="Show only errors"
            />
          }
        />
      )}
    </Box>
  );
}

export const ConsumersDataTable = ({ formik, consumers }: Props): JSX.Element => {
  const [checked, setChecked] = useState<boolean>(false);
  const [rows, setRows] = useState<ConsumerCode[]>([]);
  const [errors, setErrors] = useState<number>(0);
  const columns: GridColumns<DataGridProps> = COLUMNS_CONSUMER_WITH_DATA.map((item) => ({
    ...item,
    renderCell: (params: GridRenderCellParams<string>) => {
      const { field, id, value } = params;
      const cellIndex = formik.values.consumers.findIndex((cell) => cell.id === id);
      const error = formik.errors.consumers?.[cellIndex]?.[field as keyof (FormikErrors<ConsumerCode> | string)];

      return <TableCell error={error} value={value} field={field} />;
    },
    renderEditCell: (params: GridRenderEditCellParams<string>) => {
      const { field, id, value } = params;

      if (params.field === 'phone') {
        return <TableEditCellPhone id={id} value={value} field={field} />;
      }

      return <TableEditCell id={id} value={value} field={field} />;
    },
  }));

  const handleCellCommit = (e: GridCellEditCommitParams) => {
    const resultData = formik.values.consumers.map((item) => {
      if (item.id === e.id) {
        return { ...item, [e.field]: e.value as string };
      } else {
        return item;
      }
    });

    void formik.setFieldValue('consumers', resultData);
  };

  useEffect(() => {
    void consumersCodesValidationSchema
      .validate(consumers, { abortEarly: false })
      .then(() => {
        formik.setFieldValue('consumers', consumers);
      })
      .then(() => {
        setRows(consumers);
      })
      .catch(() => {
        void formik.setFieldValue('consumers', consumers);
        setRows(consumers);
      });
  }, [consumers]);

  useEffect(() => {
    if (checked) {
      setRows(formik.values.consumers.filter((consumer, index) => !!formik.errors?.consumers?.[index]));
    } else {
      setRows(formik.values.consumers);
    }
  }, [checked]);

  useEffect(() => {
    const errors: number = formik.values.consumers.reduce<number>((count, error, index) => {
      if (formik.errors?.consumers?.[index]) {
        return ++count;
      }
      return count;
    }, 0);
    setErrors(errors);
  }, [formik.errors.consumers]);

  const getCellClassName = (params: GridCellParams<string>) => {
    const cellIndex = formik.values.consumers.findIndex((cell) => cell.id === params.id);

    if (formik.errors.consumers?.[cellIndex]?.[params.field as keyof (FormikErrors<ConsumerCode> | string)]) {
      return 'error';
    }
    return '';
  };

  const filterCounsumers = () => {
    setChecked(!checked);
  };

  return (
    <GridTable
      data-testid="table-with-consumer-data"
      unsorted
      rows={rows}
      columns={columns}
      getCellClassName={getCellClassName}
      handleCellCommit={handleCellCommit}
      components={{
        Footer: CustomFooterComponent,
      }}
      componentsProps={{
        footer: {
          count: rows.length,
          checked,
          errors: errors,
          filterCounsumers,
        },
      }}
    />
  );
};
