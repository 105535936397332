import Papa, { ParseResult } from 'papaparse';
import { FileRejection } from 'react-dropzone';

import { COLUMNS_CONSUMER_WITH_DATA } from 'src/constants/columnsOfTable';
import { TEMPLATE_HEADER } from 'src/constants/template';
import {
  A_LOT_OF_CONSUMERS_ERROR,
  A_LOT_OF_FILES_ERROR,
  COLUMNS_COUNT,
  EMPTY_FILE_ERROR,
  FILE_SIZE_ERROR,
  INCORRECT_COLUMNS_ERROR,
  INVALID_TYPE_ERROR,
  MAX_ROWS,
  NO_ROWS_WITH_VALID_DATA_ERROR,
} from 'src/constants/uploadFile';
import { ConsumerCode } from 'src/types/code';
import { Snack } from 'src/types/events';

import { mapConsumerCodes } from '..';

export const validateFile = (
  acceptedFiles: File[],
  fileRejections: FileRejection[],
  onValidate: (errors: string[], consumers: ConsumerCode[], snacks: Snack[], file?: File) => void,
) => {
  const errors: string[] = [];

  if ((fileRejections?.length && acceptedFiles?.length) || fileRejections.length > 1) {
    errors.push(A_LOT_OF_FILES_ERROR);
    onValidate(errors, [], []);
    return;
  }

  fileRejections?.forEach((fileRejection: FileRejection) => {
    const { errors: fileErrors } = fileRejection;
    fileErrors.forEach((fileError) => {
      const { code } = fileError;
      if (code === 'file-too-large') {
        errors.push(FILE_SIZE_ERROR);
      } else if (code === 'file-invalid-type') {
        errors.push(INVALID_TYPE_ERROR);
      }
    });
  });

  const [curFile] = acceptedFiles ?? null;

  if (errors.length) {
    onValidate(errors, [], [], curFile);
    return;
  }

  Papa.parse(curFile, {
    complete: (results: ParseResult<string[]>) => {
      const { data } = results;
      const dataHeaders = data[0];
      const filteredData = data.filter((row, rowIndex) => row.some((col) => col.trim() !== '') && rowIndex !== 0);

      if (filteredData.length < 1) {
        errors.push(EMPTY_FILE_ERROR);
      }

      if (
        dataHeaders?.length !== COLUMNS_COUNT ||
        dataHeaders?.some((col) => !TEMPLATE_HEADER.map((header) => header.label.toLowerCase()).includes(col.toLowerCase()))
      ) {
        errors.push(INCORRECT_COLUMNS_ERROR);
      }

      if (filteredData.length > MAX_ROWS) {
        const data = filteredData;
        errors.push(A_LOT_OF_CONSUMERS_ERROR(data.length));
      }

      const headers = dataHeaders
        ? dataHeaders.map((col) => COLUMNS_CONSUMER_WITH_DATA.find((el) => el.headerName?.toLowerCase() === col.toLowerCase())?.field ?? '')
        : [];

      const { consumers, snacks } = mapConsumerCodes(filteredData, headers);

      if (consumers.length < 1 && filteredData.length > 0) {
        errors.push(NO_ROWS_WITH_VALID_DATA_ERROR);
      }

      onValidate(errors, consumers, snacks, curFile);
    },
  });
};
