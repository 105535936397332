import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiConfig } from 'src/config';
import { routes } from 'src/constants/routes';
import {
  SUCCESS_CODES_REQUEST_SNACK,
  INFO_START_DELIVERY_SNACK,
  WARNING_DUPLICATES_SNACK,
  WARNING_NOT_EXISTING_CODES_SNACK,
  SUCCESS_GET_EXISTING_CODES_REQUEST_SNACK,
} from 'src/constants/snacks';
import { setRedirectRoute, setLoading, pushSnack, setError } from 'src/store/slices/events';
import { ConsumerCode, GeneratedCode, ReceivedCode, RawGeneratedCode } from 'src/types/code';
import { setAuthHeaders } from 'src/utils';

const ReducerName = 'codes';

export type FetchProps = {
  numOfCodes: number;
  sellerAccount: number;
  consumers: ConsumerCode[];
  description: string;
};

export type FetchCodesProps = {
  sellerAccount: number;
};

export const generateCodes = createAsyncThunk(
  `${ReducerName}/generateCodes`,
  async ({ numOfCodes, sellerAccount, consumers, description }: FetchProps, { rejectWithValue, dispatch }) => {
    const body = consumers.length ? { clientId: sellerAccount, consumers } : { numOfCodes, clientId: sellerAccount };
    try {
      dispatch(setLoading(true));
      const response = await fetch(`${apiConfig.API_URL}/invitation-codes`, {
        method: 'POST',
        headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data: RawGeneratedCode[] = await response.json();

        const filteredGenratedCodes = data
          ?.filter((code: RawGeneratedCode) => code.status === 201)
          .map((code: RawGeneratedCode) => {
            const { body } = code;
            body.clientId = description;
            body.issuedDate = new Date(body.issuedDate).toLocaleDateString('en-US');
            body.expirationDate = new Date(body.expirationDate).toLocaleDateString('en-US');
            return body;
          });
        dispatch(setRedirectRoute(routes.INVITATION_CODES));

        if (filteredGenratedCodes?.length !== data.length) {
          dispatch(pushSnack(WARNING_DUPLICATES_SNACK));
        } else {
          dispatch(pushSnack(SUCCESS_CODES_REQUEST_SNACK));
        }

        if (filteredGenratedCodes.length > 0 && consumers.length > 0) {
          dispatch(pushSnack(INFO_START_DELIVERY_SNACK));
        }

        return filteredGenratedCodes;
      }
      dispatch(setError({ status: response.status, message: await response.text(), title: response.statusText }));
      return rejectWithValue(response);
    } catch (e) {
      dispatch(setError({ status: 503, message: (e as TypeError).message }));
      return rejectWithValue(e);
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export const gettingCodes = createAsyncThunk(
  `${ReducerName}/getCodes`,
  async ({ sellerAccount }: FetchCodesProps, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await fetch(`${apiConfig.API_URL}/invitation-codes?clientId=${sellerAccount}&isExpired=false`, {
        method: 'GET',
        headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      });
      if (response.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data: ReceivedCode[] = await response.json();

        if (data.length === 0) {
          dispatch(pushSnack(WARNING_NOT_EXISTING_CODES_SNACK));
        } else {
          dispatch(pushSnack(SUCCESS_GET_EXISTING_CODES_REQUEST_SNACK));
          dispatch(setRedirectRoute(routes.VIEW_EXISTING_CODES));
        }

        // @typescript-eslint/no-unsafe-return
        return data;
      }
      dispatch(setError({ status: response.status, message: await response.text(), title: response.statusText }));
      return rejectWithValue(response);
    } catch (e) {
      dispatch(setError({ status: 503, message: (e as TypeError).message }));
      return rejectWithValue(e);
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export interface CodeState {
  generatedCodes: GeneratedCode[];
  receivedCodes: ReceivedCode[];
  consumers: ConsumerCode[];
  numOfCodes: number;
  clientId: string;
}

const initialState: CodeState = {
  generatedCodes: [],
  receivedCodes: [],
  consumers: [],
  numOfCodes: 0,
  clientId: '',
};

export const codesSlice = createSlice({
  name: ReducerName,
  initialState,
  reducers: {
    removeGeneratedCodes: (state) => {
      state.generatedCodes.length = 0;
    },

    removeConsumerCodes: (state) => {
      state.consumers.length = 0;
    },

    setClientId: (state, action) => {
      state.clientId = action.payload as string;
    },

    setConsumerCodes: (state, action) => {
      state.consumers = action.payload as ConsumerCode[];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateCodes.fulfilled, (state: CodeState, action: { payload: GeneratedCode[] }) => {
      state.generatedCodes = action.payload;
    });
    builder.addCase(gettingCodes.fulfilled, (state: CodeState, action: { payload: ReceivedCode[] }) => {
      state.receivedCodes = action.payload;
    });
  },
});

export const { removeGeneratedCodes, setClientId, setConsumerCodes, removeConsumerCodes } = codesSlice.actions;
