import CloseIcon from '@mui/icons-material/Close';
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'src/auth/AuthContext';
import { ErrorForRoute, errorsRouteMap } from 'src/config/routes';
import { routes } from 'src/constants/routes';
import { DEEP_OCEAN, WHITE } from 'src/constants/theme';
import { useEvents } from 'src/hooks';

export const EventManager = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { authService } = useAuth();
  const logout = useCallback(() => authService.logout(routes.HOME), [authService]);
  const {
    events: {
      loading,
      redirectRoute,
      snacks,
      popup: { onSubmit, title, message, submitLabel, cancelLabel, open },
      error,
    },
    setRedirectRoute,
    closePopup,
    shiftSnack,
    setError,
  } = useEvents();
  const navigate = useNavigate();
  const onCancel = () => closePopup();
  const handleSubmit = () => {
    onSubmit?.();
    closePopup();
  };

  useEffect(() => {
    if (snacks?.length > 0) {
      const { message, options } = snacks[0];
      enqueueSnackbar(message, options);
      shiftSnack();
    }
  }, [snacks]);

  useEffect(() => {
    if (redirectRoute) {
      setRedirectRoute('');
      navigate(redirectRoute);
    }
  }, [redirectRoute]);

  useLayoutEffect(() => {
    document.body.style.overflow = loading ? 'hidden' : 'auto';
  }, [loading]);

  useEffect(() => {
    if (error) {
      setError();
      if (error.status === 401) {
        logout();
        return;
      }
      let errorRoutePath;
      if (error.path) {
        errorRoutePath = error.path;
      } else if (error.status) {
        errorRoutePath = errorsRouteMap.find((item: ErrorForRoute) => item.status === error?.status)?.path;
      }
      errorRoutePath && setRedirectRoute(errorRoutePath);
    }
  }, [error]);

  return (
    <>
      <Backdrop sx={{ color: WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog
        data-testid="dialog-window"
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle data-testid="dialog-title" id="alert-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          data-testid="close-button"
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: DEEP_OCEAN,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent data-testid="dialog-content" dividers>
          <DialogContentText data-testid="dialog-content-text" id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions data-testid="dialog-actions" sx={{ padding: 2 }}>
          {cancelLabel && (
            <Button data-testid="dialog-cancel" color="primary" variant="outlined" onClick={onCancel}>
              {cancelLabel}
            </Button>
          )}
          {submitLabel && (
            <Button data-testid="dialog-submit" color="primary" variant="contained" onClick={handleSubmit}>
              {submitLabel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
