import { useDispatch, useSelector } from 'react-redux';

import { AccountsState, fetchAccounts } from 'src/store/slices/accounts/accounts';

export const useAccounts = () => {
  const dispatch = useDispatch();
  return {
    fetchAccounts: () => dispatch(fetchAccounts()),
    accounts: useSelector((state: { accounts: AccountsState }) => state.accounts.accounts),
  };
};
