import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { accountsSlice } from './slices/accounts';
import { codesSlice } from './slices/codes';
import { eventsSlice } from './slices/events';

const reducer = combineReducers({
  codes: codesSlice.reducer,
  events: eventsSlice.reducer,
  accounts: accountsSlice.reducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
