import { GridColumns } from '@mui/x-data-grid';

import { consumerIdHeader } from './consumerIdHeader';

export const COLUMNS_CONSUMER_WITH_DATA: GridColumns = [
  {
    field: 'consumerId',
    headerName: 'Unique Key',
    width: 140,
    headerAlign: 'center',
    align: 'left',
    disableColumnMenu: true,
    editable: false,
    cellClassName: 'disabled-cell',
    description: 'A unique consumer identifier provided by leasing company and added in a file with consumer data. ',
    renderHeader: consumerIdHeader,
    sortable: false,
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 150,
    headerAlign: 'center',
    align: 'left',
    disableColumnMenu: true,
    editable: true,
  },
  { field: 'lastName', headerName: 'Last Name', width: 130, headerAlign: 'center', align: 'left', disableColumnMenu: true, editable: true },
  {
    field: 'email',
    headerName: 'Email Address',
    width: 182,
    headerAlign: 'center',
    align: 'left',
    disableColumnMenu: true,
    editable: true,
  },
  { field: 'phone', headerName: 'Phone Number', width: 182, headerAlign: 'center', align: 'left', disableColumnMenu: true, editable: true },
  { field: 'errors', hide: true },
];

export const COLUMNS_CREATE_INVITATION_CODES: GridColumns = [
  { field: 'invitationCode', headerName: 'Code', width: 100, headerAlign: 'center', align: 'left' },
  { field: 'clientId', headerName: 'Leasing Company', width: 240, headerAlign: 'center', align: 'left' },
  { field: 'issuedDate', headerName: 'Created on', width: 130, headerAlign: 'center', align: 'left' },
  { field: 'expirationDate', headerName: 'Expires at', width: 130, headerAlign: 'center', align: 'left' },
];

export const COLUMNS_CREATE_INVITATION_CODES_CONSUMERS: GridColumns = [
  { field: 'invitationCode', headerName: 'Code', width: 100, headerAlign: 'center', align: 'left' },
  { field: 'clientId', headerName: 'Leasing Company', width: 250, headerAlign: 'center', align: 'left' },
  { field: 'issuedDate', headerName: 'Created on', width: 140, headerAlign: 'center', align: 'left' },
  { field: 'expirationDate', headerName: 'Expires at', width: 140, headerAlign: 'center', align: 'left' },
  { field: 'email', headerName: 'Email Address', width: 212, headerAlign: 'center', align: 'left' },
  { field: 'phone', headerName: 'Phone Number', width: 146, headerAlign: 'center', align: 'left' },
];

export const COLUMNS_EXISTING_CODES_CONSUMERS: GridColumns = [
  { field: 'invitationCode', headerName: 'Code', width: 100, headerAlign: 'center', align: 'left' },
  { field: 'clientName', headerName: 'Leasing Company', width: 250, headerAlign: 'center', align: 'left' },
  { field: 'issuedDate', headerName: 'Created on', width: 140, headerAlign: 'center', align: 'left' },
  { field: 'expirationDate', headerName: 'Expires at', width: 140, headerAlign: 'center', align: 'left' },
  { field: 'vin', headerName: 'VIN', width: 212, headerAlign: 'center', align: 'left' },
  { field: 'smsOptInStatus', headerName: 'Opt status', width: 146, headerAlign: 'center', align: 'left' },
];
