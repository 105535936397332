import { createTheme } from '@mui/material/styles';

export const ERROR = '#d32f2f';
export const GRAY = '#C2C4C6';
export const RED = '#C33A00';
export const DROP_ZONE_BG = '#FAFAFA';
export const LIGHT_BLUE = '#009ECC';
export const LIGHT_BLUE_OPACITY = 'rgba(0,	158,	204, 0.2)';
const PLUM = '#7D076B';
export const DEEP_OCEAN = '#005484';
const PETAL = '#F1D5EC';
export const WHITE = '#FFFFFF';
export const LIGHT_GREY = '#E6E7E8';

export const theme = createTheme({
  palette: {
    primary: {
      main: PLUM,
    },
    secondary: {
      main: DEEP_OCEAN,
    },
    action: {
      disabledBackground: PETAL,
      disabled: WHITE,
    },
  },
});
