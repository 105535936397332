import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { GridTable, GridToolbar } from 'src/components/GridTable';
import { COLUMNS_EXISTING_CODES_CONSUMERS } from 'src/constants/columnsOfTable';
import { routes } from 'src/constants/routes';
import { useCodes } from 'src/hooks';

export const ViewExistingCodesTable = (): JSX.Element => {
  const {
    codes: { clientId, receivedCodes },
  } = useCodes();

  const navigate: NavigateFunction = useNavigate();
  const onNewButtonClick = useCallback(() => {
    navigate(routes.HOME);
  }, [navigate]);

  return (
    <Box sx={{ maxWidth: 988, m: '15px auto' }}>
      <Typography data-testid="table-title" component="div" align="center" sx={{ mt: 7, mb: 3, fontWeight: 'bold', fontSize: 24 }}>
        Existing Invitation Codes
      </Typography>
      <GridTable
        rows={receivedCodes}
        columns={COLUMNS_EXISTING_CODES_CONSUMERS}
        components={{ Toolbar: () => <GridToolbar clientId={clientId} onNewButtonClick={onNewButtonClick} /> }}
      />
    </Box>
  );
};
