import { Typography } from '@mui/material';
import { memo } from 'react';
import { CSVLink } from 'react-csv';

import { TEMPLATE_HEADER } from 'src/constants/template';
import { DEEP_OCEAN } from 'src/constants/theme';

import './DownloadTemplate.css';

export const DownloadTemplate = memo((): JSX.Element => {
  return (
    <>
      <Typography data-testid="download-text" sx={{ fontSize: '18px' }}>
        Upload a file in .csv format with consumers’ contact details using this&nbsp;
        <CSVLink
          data-testid="download-file-link"
          data={[]}
          headers={TEMPLATE_HEADER}
          filename={'Consumers_Contact_Details.csv'}
          className="csv-link"
          style={{ color: DEEP_OCEAN }}
        >
          template
        </CSVLink>
        . Unique consumer identifier, email or phone number are required.
      </Typography>
    </>
  );
});
