import { AuthTokens } from 'src/types/auth';

export const toSnakeCase = (str: string): string => {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
};

export const toUrlEncoded = (obj: Record<string, string>): string => {
  return Object.keys(obj)
    .map((k) => encodeURIComponent(toSnakeCase(k)) + '=' + encodeURIComponent(obj[k]))
    .join('&');
};

export function setAuthHeaders(headers?: Record<string, string>) {
  if (localStorage.getItem('auth')) {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}') as AuthTokens;
    return {
      ...headers,
      Authorization: `${auth.token_type} ${auth.access_token}`,
    };
  } else {
    return headers;
  }
}
