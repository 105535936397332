import { useGridApiContext } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { IMaskInput } from 'react-imask';

type Props = {
  value?: string;
  id: number | string;
  field: string;
  error?: string;
};

const phoneNumberMask = '(000) 000-0000';

const TableEditCellPhone = ({ id, value, field }: Props): JSX.Element => {
  const apiRef = useGridApiContext();

  const handleAcceptValue = (value: unknown) => {
    void apiRef.current.setEditCellValue({ id, field, value });
  };

  useEffect(() => {
    void apiRef.current.setEditCellValue({ id, field, value: value?.replace(/^\+\d/g, '') });
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
      <IMaskInput
        value={value}
        mask={phoneNumberMask}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        className="maskedInput"
        onAccept={handleAcceptValue}
        data-testid="phone-input"
        autoFocus
      />
    </div>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.value === nextProps.value && prevProps.id === nextProps.id;
};

export default React.memo(TableEditCellPhone, areEqual);
