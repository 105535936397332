import { ConsumerCode } from 'src/types/code';

interface HeaderCell {
  label: string;
  key: keyof ConsumerCode;
}

export const TEMPLATE_HEADER: HeaderCell[] = [
  { label: 'Unique Key', key: 'consumerId' },
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email Address', key: 'email' },
  { label: 'Phone Number', key: 'phone' },
];
