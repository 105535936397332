import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Box, Button, ButtonProps } from '@mui/material';
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  GridRowId,
  gridSortedRowIdsSelector,
  GridToolbarContainer,
  useGridApiContext,
  GridCellEditCommitParams,
  DataGridProps,
  GridRowsProp,
  GridColumns,
  DataGrid,
  GridSlotsComponent,
  GridSortItem,
  GridCellParams,
  GridSlotsComponentsProps,
  GridSortModel,
} from '@mui/x-data-grid';
import { useMemo, useState } from 'react';

import { LIGHT_GREY } from 'src/constants/theme';
import { ConsumerCode, GeneratedCode } from 'src/types/code';

const dataGridConfig = {
  sx: {
    border: 'none',
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: LIGHT_GREY,
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
  },
  initialState: {},
  rowsPerPageOptions: [20, 50, 100],
  autoHeight: true,
  disableColumnFilter: true,
  disableColumnMenu: true,
  disableColumnSelector: true,
  disableDensitySelector: true,
  disableSelectionOnClick: true,
  disableVirtualization: true,
} as unknown as DataGridProps;

const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams): GridRowId[] => gridSortedRowIdsSelector(apiRef);

const buttonBaseProps: ButtonProps = {
  color: 'primary',
  size: 'medium',
};

type GridToolbarProps = {
  onNewButtonClick?: () => void;
  clientId: string;
};

export const GridToolbar = ({ onNewButtonClick, clientId }: GridToolbarProps): JSX.Element => {
  const apiRef = useGridApiContext();
  const csvExportOptions = useMemo(
    () => ({
      getRowsToExport: getUnfilteredRows,
      fileName: `${clientId}_${new Date().toLocaleDateString('en-US').replace(/\/+/g, '-')}`,
    }),
    [clientId],
  );

  const handleExport = (options: GridCsvExportOptions): void => apiRef.current.exportDataAsCsv(options);

  return (
    <GridToolbarContainer>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mb: 1, padding: 0 }}>
        <Button
          data-testid="new-request-btn"
          sx={{ mr: 3 }}
          {...buttonBaseProps}
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={onNewButtonClick}
        >
          new request
        </Button>
        <Button
          data-testid="export-btn"
          {...buttonBaseProps}
          startIcon={<GetAppIcon />}
          variant="contained"
          onClick={() => handleExport(csvExportOptions)}
        >
          export
        </Button>
      </Box>
    </GridToolbarContainer>
  );
};

type Props = {
  rows: GridRowsProp;
  columns: GridColumns<DataGridProps>;
  components?: Partial<GridSlotsComponent> | undefined;
  componentsProps?: Partial<GridSlotsComponentsProps> | undefined;
  handleCellCommit?: (params: GridCellEditCommitParams) => void;
  getCellClassName?: (params: GridCellParams<string>) => string;
  unsorted?: boolean;
};

export const GridTable = ({
  rows,
  columns,
  components,
  componentsProps,
  handleCellCommit,
  getCellClassName,
  unsorted,
}: Props): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(20);
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: components ? 'codesCol' : 'consumerId',
      sort: 'asc',
    },
  ]);

  return (
    <DataGrid
      getRowId={(row: Pick<ConsumerCode, 'consumerId'> & Pick<GeneratedCode, 'invitationCode'>) => row.invitationCode ?? row.consumerId}
      {...dataGridConfig}
      components={components}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
      rows={rows}
      columns={columns}
      sortModel={unsorted ? undefined : sortModel}
      onCellEditCommit={handleCellCommit}
      getCellClassName={getCellClassName}
      onSortModelChange={(model: GridSortModel) => setSortModel(model)}
      componentsProps={componentsProps}
    />
  );
};
