import { Tooltip } from '@mui/material';
import React from 'react';

import { RED } from '../../constants/theme';

type Props = {
  value?: string;
  error?: string;
  field: string;
};

const TableCell = ({ error, value, field }: Props): JSX.Element => {
  return error ? (
    <Tooltip
      title={error}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: `${RED}`,
          },
        },
        arrow: {
          sx: {
            '&::before': {
              backgroundColor: `${RED}`,
            },
          },
        },
      }}
    >
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
        <span>{value}</span>
      </div>
    </Tooltip>
  ) : (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
      <span>{field === 'phone' && !error ? value?.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3') : value}</span>
    </div>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.value === nextProps.value && prevProps.error === nextProps.error;
};

export default React.memo(TableCell, areEqual);
