import { WARNING_INVALID_DATA_SNACK } from 'src/constants/snacks';
import { ConsumerCode } from 'src/types/code';
import { Snack } from 'src/types/events';

export const mapConsumerCodes = (data: string[][], headers: string[]): { consumers: ConsumerCode[]; snacks: Snack[] } => {
  const snacks: Snack[] = [];

  const filteredData: ConsumerCode[] = data
    .reduce<ConsumerCode[]>((consumers, row) => {
      const code: ConsumerCode = headers.reduce<ConsumerCode>((code, key, colIndex) => {
        code[key as keyof ConsumerCode] = row[colIndex];
        return code;
      }, {} as ConsumerCode);

      code.id = code.consumerId;

      consumers.push(code);

      return consumers;
    }, [])
    .filter((code, index, consumers) => {
      const { consumerId, email, phone } = code;

      if (!consumerId || (!email && !phone) || consumers.findIndex((item) => item.consumerId === consumerId) !== index) {
        return false;
      }

      return true;
    });

  if (filteredData.length !== data.length) {
    snacks.push(WARNING_INVALID_DATA_SNACK);
  }

  return { consumers: filteredData, snacks };
};
