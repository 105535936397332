import { Box, Grid, Typography } from '@mui/material';

export type ErrorProps = {
  title: string;
  status?: number;
  message: string;
  children?: React.ReactNode;
  element?: (props: ErrorProps) => JSX.Element;
};

export const ErrorPage = ({ title, status, message, children }: ErrorProps): JSX.Element => {
  return (
    <Box data-testid="error-page" sx={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container sx={{ maxWidth: '373px', textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography data-testid="error-code" component="div" sx={{ fontWeight: 700, fontSize: '96px' }} color="purple">
            {status}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography data-testid="error-title" component="div" color="#25292E" sx={{ fontWeight: 700, fontSize: '24px' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography data-testid="error-text" component="div" color="#25292E" sx={{ fontSize: '18px' }}>
            {message}
          </Typography>
        </Grid>
        {children}
      </Grid>
    </Box>
  );
};
