import { useState, useCallback, useMemo } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { DROP_ZONE_BG, ERROR, GRAY, LIGHT_BLUE, LIGHT_BLUE_OPACITY } from 'src/constants/theme';
import { MAX_FILES, MAX_FILE_SIZE, FILE_FORMAT } from 'src/constants/uploadFile';
import { ConsumerCode } from 'src/types/code';
import { Snack } from 'src/types/events';
import { validateFile } from 'src/utils';

import { useCodes, useEvents } from '..';

export const useFileUpload = () => {
  const [file, setFile] = useState<File>();
  const [errors, setErrors] = useState<string[]>([]);
  const { setConsumerCodes, removeConsumerCodes } = useCodes();
  const { pushSnack } = useEvents();
  const removeFile = () => {
    setFile(undefined);
    removeConsumerCodes();
    setErrors([]);
  };

  const baseStyle = {
    maxWidth: 784,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 6,
    borderColor: GRAY,
    borderStyle: 'dashed',
    backgroundColor: DROP_ZONE_BG,
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const acceptStyle = {
    borderColor: LIGHT_BLUE,
    backgroundColor: LIGHT_BLUE_OPACITY,
  };

  const rejectStyle = {
    borderColor: ERROR,
  };

  const onValidate = (errors: string[], consumers: ConsumerCode[], snacks: Snack[], file?: File) => {
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    if (snacks) {
      snacks.forEach((snack) => pushSnack(snack));
    }

    setConsumerCodes(consumers);
    setFile(file);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
      setErrors([]);
      validateFile(acceptedFiles, fileRejections, onValidate);
    },
    [errors, file],
  );

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxFiles: MAX_FILES,
    maxSize: MAX_FILE_SIZE,
    accept: FILE_FORMAT,
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject],
  );

  return {
    getRootProps,
    getInputProps,
    open,
    removeFile,
    errors,
    file,
    style,
  };
};
