import { createSlice } from '@reduxjs/toolkit';

import { Snack, Error, Popup } from 'src/types/events';

const ReducerName = 'events';

export interface EventsState {
  loading: boolean;
  snacks: Snack[];
  redirectRoute?: string;
  popup: Popup;
  error?: Error;
}

const initialState: EventsState = {
  loading: false,
  popup: {
    open: false,
    onSubmit: undefined,
    title: '',
    message: '',
    submitLabel: '',
    cancelLabel: '',
  },
  snacks: [],
};

export const eventsSlice = createSlice({
  name: ReducerName,
  initialState,
  reducers: {
    setLoading: (state: EventsState, action: { payload: boolean }) => {
      state.loading = action.payload;
    },

    setRedirectRoute: (state: EventsState, action: { payload: string }) => {
      state.redirectRoute = action.payload;
    },

    pushSnack(state: EventsState, action: { payload: Snack }) {
      state.snacks = [...state.snacks, action.payload];
    },

    shiftSnack(state: EventsState) {
      state.snacks = state.snacks.slice(1);
    },

    setError(state: EventsState, action: { payload: Error | undefined }) {
      state.error = action.payload;
    },

    closePopup(state: EventsState) {
      state.popup.open = false;
    },

    openPopup(state: EventsState, action: { payload: Omit<Popup, 'open'> }) {
      state.popup = { open: true, ...action.payload };
    },
  },
});

export const { setLoading, setRedirectRoute, pushSnack, shiftSnack, setError, openPopup, closePopup } = eventsSlice.actions;
