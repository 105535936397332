export const MAX_FILE_SIZE = 10240000;
export const MAX_FILES = 1;
export const COLUMNS_COUNT = 5;
export const MAX_ROWS = 1000;
export const FILE_FORMAT = { 'text/csv': ['.csv'] };
export const EMPTY_FILE_ERROR = 'File must not be empty';
export const INCORRECT_COLUMNS_ERROR = 'Columns in the file are not correct. Use template to add file';
export const A_LOT_OF_FILES_ERROR = 'Only one file can be uploaded';
export const A_LOT_OF_CONSUMERS_ERROR = (consumers: number) =>
  `We found ${consumers} consumers in the file. Max allowed number is 1000. Submit another request if there are more consumers`;
export const FILE_SIZE_ERROR = 'File size must not exceed 10 Mb';
export const INVALID_TYPE_ERROR = 'Uploaded file is not in .csv format';
export const NO_ROWS_WITH_VALID_DATA_ERROR = 'No rows with valid data were found';
