import jwtDecode from 'jwt-decode';

import { AuthTokens, TokenRequestBody, AuthServiceProps, DecodedUser } from 'src/types/auth';
import { Error } from 'src/types/events';
import { toUrlEncoded } from 'src/utils';
export class AuthService {
  props: AuthServiceProps;
  timeout?: number;
  authTokens: Partial<AuthTokens>;
  user: DecodedUser;
  errorHandler?: (e: Error) => void;

  constructor(props: AuthServiceProps) {
    this.props = props;
    this.user = this.getItem('user', '{}') as DecodedUser;
    this.authTokens = this.getItem('auth', '{}') as AuthTokens;
    const code = this.getCodeFromLocation(window.location);
    if (code !== null) {
      this.fetchToken(code)
        .then(() => {
          this.restoreUri();
        })
        .catch((e: Error) => {
          this.removeItem('user');
          this.removeItem('auth');
          this.removeCodeFromLocation();
          if (this.errorHandler) {
            this.errorHandler(e);
          }
        });
    }
  }

  setErrorHandler(func: (e: Error) => void) {
    this.errorHandler = func;
  }

  setUser(id_token: string): DecodedUser | unknown {
    if (!id_token) return {};
    const decoded = jwtDecode(id_token);
    this.setItem('user', decoded);
    this.user = decoded as DecodedUser;
    return decoded;
  }

  getCodeFromLocation(location: Location): string | null {
    const split = location.toString().split('?');
    if (split.length < 2) {
      return null;
    }
    const pairs = split[1].split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      if (key === 'code') {
        return decodeURIComponent(value || '');
      }
    }
    return null;
  }

  removeCodeFromLocation(): void {
    const [base, search] = window.location.href.split('?');
    if (!search) {
      return;
    }
    const newSearch = search
      .split('&')
      .map((param) => param.split('='))
      .filter(([key]) => key !== 'code')
      .map((keyAndVal) => keyAndVal.join('='))
      .join('&');
    window.history.replaceState(window.history.state, 'null', base + (newSearch.length ? `?${newSearch}` : ''));
  }

  setItem(key: string, obj: unknown) {
    window.localStorage.setItem(key, JSON.stringify(obj));
  }

  getItem(key: string, defValue = '{}') {
    return JSON.parse(window.localStorage.getItem(key) || defValue) as unknown;
  }

  removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }

  setAuthTokens(auth: AuthTokens): void {
    window.localStorage.setItem('auth', JSON.stringify(auth));
    this.authTokens = auth;
  }

  isAuthenticated(): boolean {
    return !!this.authTokens.access_token;
  }

  logout(defaultRoute?: string, shouldEndSession = false) {
    this.removeItem('auth');
    this.removeItem('user');
    if (shouldEndSession) {
      const { clientId, provider, logoutEndpoint, redirectUri } = this.props;
      const query = {
        client_id: clientId,
        post_logout_redirect_uri: redirectUri,
      };
      const url = `${logoutEndpoint || `${provider}/logout`}?${toUrlEncoded(query as unknown as Record<string, string>)}`;
      window.location.replace(url);
      return true;
    } else {
      if (defaultRoute) {
        window.location.replace(defaultRoute);
      } else {
        window.location.reload();
      }
      return true;
    }
  }

  login() {
    this.authorize();
  }

  // this will do a full page reload and to to the OAuth2 provider's login page and then redirect back to redirectUri
  authorize(): boolean {
    const { clientId, provider, authorizeEndpoint, redirectUri, scopes, audience, extraParams } = this.props;

    this.setItem('preAuthUri', location.href);
    this.removeItem('auth');

    const query: Record<string, string> = {
      clientId,
      scope: scopes.join(' '),
      responseType: 'code',
      ...(audience && { audience }),
      ...extraParams,
    };
    if (redirectUri) {
      query.redirectUri = redirectUri;
    }
    // Responds with a 302 redirect
    const url = `${authorizeEndpoint || `${provider}`}?${toUrlEncoded(query)}`;
    window.location.replace(url);
    return true;
  }

  // this happens after a full page reload. Read the code from localstorage
  async fetchToken(code: string, isRefresh = false): Promise<Partial<AuthTokens>> {
    const { clientId, clientSecret, contentType, provider, tokenEndpoint, redirectUri } = this.props;
    const grantType = 'authorization_code';

    let payload: TokenRequestBody = {
      clientId,
      ...(clientSecret ? { clientSecret } : {}),
      redirectUri,
      grantType,
    };
    if (isRefresh) {
      payload = {
        ...payload,
        grantType: 'refresh_token',
        refresh_token: code,
      };
    } else {
      payload = {
        ...payload,
        code,
      };
    }

    try {
      const response = await fetch(`${tokenEndpoint || `${provider}/token`}`, {
        headers: {
          'Content-Type': contentType || 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        body: toUrlEncoded(payload as unknown as Record<string, string>),
      });
      const json = (await response.json()) as AuthTokens;

      if (response.status === 200) {
        if (isRefresh && !json.refresh_token) {
          json.refresh_token = payload.refresh_token || '';
        }
        if (json.id_token) {
          this.setUser(json.id_token);
        }
        this.setAuthTokens(json);
        return Promise.resolve(this.authTokens);
      }
      return Promise.reject(json);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  restoreUri(): void {
    const uri = this.getItem('preAuthUri') as string;
    this.removeItem('preAuthUri');
    if (uri !== null) {
      window.location.replace(uri);
    }
    this.removeCodeFromLocation();
  }
}
