import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ERROR, GRAY } from 'src/constants/theme';
import { useEvents, useFileUpload } from 'src/hooks';
import { ReactComponent as SvgFileIcon } from 'src/icons/csv-icon.svg';

const title = 'Remove File?';
const message =
  "If you confirm this action, the table with consumers' contact details will " +
  'be gone and you might lose the changes you have made to the data if any.';
const cancelLabel = 'CANCEL';
const submitLabel = 'REMOVE';

const UploadFile = (): JSX.Element => {
  const { getRootProps, getInputProps, open, removeFile, errors, file, style } = useFileUpload();
  const { openPopup } = useEvents();
  const [fileSize, setFileSize] = useState<string>('0 bytes');

  useEffect(() => {
    if (file !== undefined && file.size >= 1024 * 1024) {
      setFileSize(`${Math.round(file.size / 1024 / 1024)} MB`);
    } else if (file !== undefined && file.size >= 1024) {
      setFileSize(`${Math.round(file.size / 1024)} KB`);
    } else if (file !== undefined) {
      setFileSize(`${file.size} bytes`);
    }
  }, [file]);

  return (
    <Box data-testid="drop-container">
      {!file && (
        <Box data-testid="drop-wrap" {...getRootProps({ style })}>
          <input data-testid="drop-input" {...getInputProps()} />
          <Typography data-testid="drop-upload-box" sx={{ display: 'flex', alignContent: 'center', fontSize: '18px' }}>
            <FileUploadIcon data-testid="drop-upload-icon" color="secondary" />
            Drop file to attach, or
            <Typography
              data-testid="drop-upload-btn"
              component="span"
              onClick={open}
              color="secondary"
              sx={{ cursor: 'pointer', fontSize: '18px', fontWeight: 700 }}
            >
              &nbsp;browse
            </Typography>
          </Typography>
        </Box>
      )}
      {file && (
        <Box
          data-testid="drop-file-box"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: 784,
            height: 68,
            border: errors.length ? `1px solid ${ERROR}` : `1px solid ${GRAY}`,
            borderRadius: '6px',
          }}
        >
          <Box data-testid="drop-file-wrap" sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
            <SvgFileIcon />
            <Box data-testid="drop-file-box" key={file.size} sx={{ ml: 2 }}>
              <Typography data-testid="drop-file-name" sx={{ fontSize: 16 }}>
                {file.name}
              </Typography>
              <Typography data-testid="drop-file-size" sx={{ fontSize: 14 }}>
                {fileSize}
              </Typography>
            </Box>
          </Box>
          <Box data-testid="drop-file-remove" sx={{ m: 1 }}>
            <CloseIcon
              data-testid="drop-file-remove-btn"
              onClick={() =>
                openPopup({
                  title,
                  message,
                  cancelLabel,
                  submitLabel,
                  onSubmit: removeFile,
                })
              }
              fontSize="large"
              color="secondary"
              sx={{ cursor: 'pointer' }}
            />
          </Box>
        </Box>
      )}
      {errors.length > 0 &&
        errors.map((errorMessage) => (
          <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', color: ERROR }} data-testid="drop-error" key={errorMessage}>
            {errorMessage}
          </Typography>
        ))}
    </Box>
  );
};

const MemorizedUploadFile = React.memo(UploadFile);
export { MemorizedUploadFile as UploadFile };
