import { useDispatch, useSelector } from 'react-redux';

import { EventsState, setRedirectRoute, setLoading, pushSnack, shiftSnack, setError } from 'src/store/slices/events';
import { closePopup, openPopup } from 'src/store/slices/events/events';
import { Popup, Snack, Error } from 'src/types/events';

export const useEvents = () => {
  const dispatch = useDispatch();
  return {
    events: useSelector((state: { events: EventsState }) => state.events),
    snacks: useSelector((state: { events: EventsState }) => state.events.snacks),
    setRedirectRoute: (route: string) => dispatch(setRedirectRoute(route)),
    setLoading: (newState: boolean) => dispatch(setLoading(newState)),
    pushSnack: (snack: Snack) => dispatch(pushSnack(snack)),
    shiftSnack: () => dispatch(shiftSnack()),
    openPopup: (popup: Omit<Popup, 'open'>) => dispatch(openPopup(popup)),
    closePopup: () => dispatch(closePopup()),
    setError: (e?: Error) => dispatch(setError(e)),
  };
};
