import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

import { routes } from 'src/constants/routes';
import { WHITE } from 'src/constants/theme';
import { CreateInvitationCode } from 'src/pages/CreateInvitationCodes';
import { ErrorPage, ErrorProps } from 'src/pages/ErrorPages';
import { InvitationCodesTable } from 'src/pages/InvitationCodesTable';
import { ViewExistingCodesTable } from 'src/pages/ViewExistingCodesTable';

export type RouteType = { path: string; element: React.ReactNode | null };

export const routesMap: RouteType[] = [
  {
    path: routes.HOME,
    element: <CreateInvitationCode />,
  },
  {
    path: routes.INVITATION_CODES,
    element: <InvitationCodesTable />,
  },
  {
    path: routes.VIEW_EXISTING_CODES,
    element: <ViewExistingCodesTable />,
  },
  {
    path: routes.LOGIN_CALLBACK,
    element: (
      <Backdrop sx={{ color: WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="primary" />
      </Backdrop>
    ),
  },
];

export type ErrorForRoute = ErrorProps & { path: string };

export const errorsRouteMap: ErrorForRoute[] = [
  {
    status: 500,
    path: routes.ERROR_500,
    message: 'Something went wrong. Retry your actions or come back later.',
    title: 'Unexpected Error',
  },
  {
    status: 400,
    path: routes.ERROR_400,
    message: 'The server was unable to process the request. Retry your actions.',
    title: 'Bad Request',
  },
  {
    status: 503,
    path: routes.ERROR_503,
    message: 'The server is temporarily busy. Try again later.',
    title: 'Service Unavailable',
  },
  {
    status: 404,
    title: 'Page Not Found',
    path: routes.ERROR_404,
    message: 'The requested URL was not found on the server.',
  },
  {
    status: 404,
    title: 'Page Not Found',
    path: routes.OTHER,
    message: 'The requested URL was not found on the server.',
  },
  {
    title: 'No Available Leasing Companies',
    path: routes.ERROR_NOT_AVAILABLE_LEASING_COMPANIES,
    message: 'We are unable to return leasing companies now, they could be in the process of being setup. Please try again later',
  },
];

errorsRouteMap.forEach((routeProps: ErrorForRoute) =>
  routesMap.push({
    path: routeProps.path,
    element: routeProps.element ? <routeProps.element {...routeProps} /> : <ErrorPage {...routeProps} />,
  }),
);
