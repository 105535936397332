import CloseIcon from '@mui/icons-material/Close';
import { IconButton, ThemeProvider } from '@mui/material';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { createRef, ReactNode, RefObject } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthProvider } from 'src/auth/AuthProvider';
import { AuthService } from 'src/auth/AuthService';
import { theme, DEEP_OCEAN } from 'src/constants/theme';

import { AuthComponent } from './auth/AuthComponent';
import { EventManager } from './components/EventManager';
import { routesMap, RouteType, authConfig } from './config';

const authService = new AuthService(authConfig);

const notistackRef: RefObject<SnackbarProvider> = createRef();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};

export const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        data-testid="snackbar-provider"
        ref={notistackRef}
        action={(key: SnackbarKey): ReactNode => (
          <IconButton
            data-testid="close-button"
            aria-label="close"
            onClick={onClickDismiss(key)}
            sx={{
              color: DEEP_OCEAN,
            }}
          >
            <CloseIcon data-testid="close-icon" />
          </IconButton>
        )}
      >
        <AuthProvider authService={authService}>
          <Routes>
            <Route path="/" element={<AuthComponent />}>
              {routesMap.map(({ path, element }: RouteType) => (
                <Route path={path} element={element} key={path} />
              ))}
            </Route>
          </Routes>
          <EventManager />
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
