import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Grid, Button, Typography, ButtonGroup, TextField, MenuItem, FormHelperText } from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import { useEffect, useState, ChangeEvent } from 'react';
import * as yup from 'yup';

import { consumersCodesValidationSchema, ConsumersDataTable } from 'src/components/ConsumersDataTable';
import { DownloadTemplate } from 'src/components/DownloadTemplate';
import { UploadFile } from 'src/components/UploadFile';
import {
  MAX_OF_CODES,
  NUM_OF_CODES_ERROR_MESSAGE,
  STEP_1,
  STEP_2,
  STEP_1_TEXT,
  STEP_2_TEXT_CLIENT,
  STEP_2_TEXT_CONSUMER,
  CLIENT,
  CONSUMER,
  MAX_WIDTH,
} from 'src/constants/CreateInvitationCodesForm';
import './CreateInvitationCodes.css';
import { useCodes, useEvents, useAccounts } from 'src/hooks';
import { Account } from 'src/types/accounts';
import { InitialValuesFormik } from 'src/types/code';

import { RED } from '../../constants/theme';

const initData = {
  clientId: '',
  sellerAccount: 0,
  numOfCodes: 0,
  consumers: [],
};

const validationSchemaClinetB = yup.object({
  clientId: yup.string().required('Leasing Company is required'),
  numOfCodes: yup
    .number()
    .required(NUM_OF_CODES_ERROR_MESSAGE)
    .positive(NUM_OF_CODES_ERROR_MESSAGE)
    .integer(NUM_OF_CODES_ERROR_MESSAGE)
    .max(MAX_OF_CODES, NUM_OF_CODES_ERROR_MESSAGE),
  consumers: consumersCodesValidationSchema,
});

const validationSchemaClinetA = yup.object({
  clientId: yup.string().required('Leasing Company is required'),
  consumers: consumersCodesValidationSchema,
});

const title = 'Change Leasing Company?';
const message = 'If you confirm this action, all the data filled in the request form will be gone and new request will need to be created.';
const cancelLabel = 'CANCEL';
const submitLabel = 'CHANGE';

const renderStep = (step: string, text: string, clientId?: string) => {
  return (
    <Box data-testid={`form-${step}-box`} sx={{ display: 'flex', mt: 3, mb: 3 }}>
      <Typography data-testid={`form-${step}-title`} component="div" sx={{ minWidth: '70px', fontWeight: 'bold', fontSize: 18 }}>
        {step}:&nbsp;
      </Typography>
      {(step === STEP_1 || clientId === CLIENT) && (
        <Typography data-testid={`form-${step}-text`} component="span" sx={{ fontSize: 18 }}>
          {text}
        </Typography>
      )}
      {clientId === CONSUMER && <DownloadTemplate />}
    </Box>
  );
};

const renderSubmit = () => (
  <Box sx={{ width: '100%' }}>
    <Button data-testid="form-submit" type="submit" color="primary" variant="contained" sx={{ mt: 3, mb: 4, width: '100%', height: 40 }}>
      Submit
    </Button>
  </Box>
);

export const CreateInvitationCode = (): JSX.Element => {
  const [validationSchema, setValidationSchema] = useState(validationSchemaClinetA);
  const {
    generateCodes,
    gettingCodes,
    setClientId,
    removeConsumerCodes,
    codes: { consumers },
  } = useCodes();
  const { fetchAccounts, accounts } = useAccounts();
  const { openPopup } = useEvents();

  const onSubmit = () => {
    const selectedAccount = accounts.find((item: Account) => item.client === formik.values.clientId);

    setClientId(formik.values.clientId);
    generateCodes({
      ...formik.values,
      sellerAccount: selectedAccount?.sellerAccounts?.[0] ?? 0,
      description: selectedAccount?.description ?? selectedAccount?.client ?? '',
    });
  };

  const viewStepTwo = (clientId: string) => {
    const account = accounts?.find((account) => account.client === clientId);
    return account?.invitationCode.exportMethod;
  };

  const formik = useFormik({
    initialValues: initData,
    validateOnChange: true,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!accounts.length) {
      fetchAccounts();
    }
  }, []);

  useEffect(() => {
    setValidationSchema(viewStepTwo(formik.values.clientId) === CONSUMER ? validationSchemaClinetA : validationSchemaClinetB);
  }, [formik.values.clientId]);

  const viewExistingCodes = () => {
    const selectedAccount = accounts.find((item: Account) => item.client === formik.values.clientId);

    gettingCodes({
      sellerAccount: selectedAccount?.sellerAccounts?.[0] ?? 0,
    });
  };

  const incrementCodes = (incrementor: number) => {
    let newValue = formik.values.numOfCodes + incrementor;
    if (formik.values.numOfCodes === 0 && incrementor < 0) {
      newValue = formik.values.numOfCodes;
    }
    formik.setFieldValue('numOfCodes', newValue).then(() => {
      formik.setFieldTouched('numOfCodes') as unknown;
    }) as unknown;
  };

  const handleChangeSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (formik.values.consumers.length > 0 || formik.values.numOfCodes > 0) {
      openPopup({
        title,
        message,
        cancelLabel,
        submitLabel,
        onSubmit: () => {
          removeConsumerCodes();
          formik.resetForm();
          formik.handleChange(event);
        },
      });
    } else {
      removeConsumerCodes();
      formik.resetForm();
      formik.handleChange(event);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        m: '10vh auto',
        maxWidth: MAX_WIDTH,
        '& .error': {
          boxShadow: `0px 0px 0px 1px ${RED} inset`,
          borderRadius: '2px',
        },
        '& .maskedInput': {
          width: '100%',
          height: '100%',
          padding: '0 10px',
          font: 'inherit',
        },
      }}
    >
      <Grid container spacing={1} sx={{ m: 0, boxSizing: 'border-box', maxWidth: MAX_WIDTH }}>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Typography data-testid="form-title" component="div" align="center" sx={{ fontWeight: 'bold', fontSize: 24 }}>
              Request Invitation Code
            </Typography>
          </Grid>
          {renderStep(STEP_1, STEP_1_TEXT)}
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography data-testid="form-label-company" component="div" sx={{ fontWeight: 'bold', fontSize: 18, mb: 1 }}>
              Leasing Company
            </Typography>
            <TextField
              data-testid="form-select-company"
              sx={{ width: '100%' }}
              select
              value={formik.values.clientId}
              name="clientId"
              id="clientId"
              onChange={handleChangeSelect}
              onBlur={formik.handleBlur}
              label={formik.values.clientId ? ' ' : 'Select Leasing Company'}
              InputLabelProps={{ shrink: false }}
              error={!!formik.errors.clientId && formik.touched.clientId}
            >
              {accounts.map((account: Account) => (
                <MenuItem key={account.client} value={account.client}>
                  {account.description ?? account.client}
                </MenuItem>
              ))}
            </TextField>
            {!!formik.errors.clientId && formik.touched.clientId && (
              <FormHelperText data-testid="form-error-company" error>
                {formik.errors.clientId}
              </FormHelperText>
            )}
          </Grid>
          {viewStepTwo(formik.values.clientId) === CONSUMER && (
            <>
              <Button data-testid="view-existing-codes-btn" onClick={viewExistingCodes}>
                VIEW EXISTING CODES
              </Button>
              {renderStep(STEP_2, STEP_2_TEXT_CONSUMER, CONSUMER)}
              <UploadFile />
              {consumers?.length > 0 && (
                <Box sx={{ width: '100%' }}>
                  <Typography data-testid="table-text" sx={{ mt: 2, mb: 3 }}>
                    Double check consumers’ contact details. Tap in a cell to edit.
                  </Typography>
                  <ConsumersDataTable formik={formik as unknown as FormikProps<InitialValuesFormik>} consumers={consumers} />
                </Box>
              )}
              {consumers?.length > 0 && renderSubmit()}
            </>
          )}
          {viewStepTwo(formik.values.clientId) === CLIENT && (
            <>
              <Button data-testid="view-existing-codes-btn" onClick={viewExistingCodes}>
                VIEW EXISTING CODES
              </Button>
              <Box sx={{ height: '100px', mb: 5 }}>
                {renderStep(STEP_2, STEP_2_TEXT_CLIENT, CLIENT)}
                <Typography data-testid="form-label-codes" component="div" sx={{ fontWeight: 'bold', fontSize: 18, mb: 1 }}>
                  Required Number of Codes
                </Typography>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Button
                    disabled={formik.values.numOfCodes <= 0}
                    color="primary"
                    data-testid="form-minus-btn"
                    onClick={() => incrementCodes(-1)}
                    sx={{ width: 40 }}
                  >
                    <RemoveIcon />
                  </Button>
                  <TextField
                    data-testid="form-input-codes"
                    type="number"
                    value={formik.values.numOfCodes}
                    onChange={formik.handleChange}
                    name="numOfCodes"
                    id="codes-amount"
                    error={!!formik.errors.numOfCodes && !!formik.touched.numOfCodes}
                    inputProps={{ style: { textAlign: 'center', padding: '9px 6px' } }}
                    sx={{ width: 70, height: 40, fontSize: 16, br: 'none' }}
                  />
                  <Button data-testid="form-plus-btn" color="primary" onClick={() => incrementCodes(1)} sx={{ width: 40 }}>
                    <AddIcon />
                  </Button>
                </ButtonGroup>
                {formik.errors.numOfCodes && formik.touched.numOfCodes && (
                  <FormHelperText data-testid="form-error-codes" error>
                    {formik.errors.numOfCodes}
                  </FormHelperText>
                )}
              </Box>
              {renderSubmit()}
            </>
          )}
        </form>
      </Grid>
    </Box>
  );
};
