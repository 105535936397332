import { VariantType } from 'notistack';

export const ALERT_MESSAGE_SUCCESS = 'Request submitted. Invitation codes have been created.';
export const ALERT_MESSAGE_SUCCESS_GET_EXISTING_CODES = 'Request submitted. Existing codes have been found.';
export const ALERT_MESSAGE_WARNING_INVALID_DATA =
  'Invalid data was found in the file. Only rows with unique key values and filled email or phone are displayed in the table.';
export const ALERT_MESSAGE_INFO_START_DELIVERY = 'Delivery of invitation codes to consumers has been started.';
export const ALERT_MESSAGE_WARNING_CONSUMER_DUPLICATE =
  'Duplicates of existing invitation codes were found. Only net new invitation codes were created and are displayed is the table.';
export const ALERT_TYPE_SUCCESS: VariantType = 'success';
export const ALERT_TYPE_ERROR: VariantType = 'error';
export const ALERT_TYPE_WARNING: VariantType = 'warning';
export const ALERT_TYPE_INFO: VariantType = 'info';
export const ALERT_MESSAGE_WARNING_NOT_EXIST_CODES = 'There are no existing codes for this client';

export const ALERT_AUTOHIDE_DURATION = 10000;
export const ALERT_PREVENT_DUPLICATES = true;
