export const routes = {
  HOME: '/',
  INVITATION_CODES: '/invitation-codes',
  VIEW_EXISTING_CODES: '/view-existing-codes',
  UPLOAD: '/upload',
  ERROR_400: '/error-400',
  ERROR_404: '/error-404',
  ERROR_500: '/error-500',
  ERROR_503: '/error-503',
  ERROR_NOT_AVAILABLE_CODES: '/error-not-available-codes',
  ERROR_NOT_AVAILABLE_LEASING_COMPANIES: '/error-not-available-leasing-companies',
  OTHER: '/*',
  LOGIN_CALLBACK: '/login/callback',
};
