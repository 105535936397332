import { useDispatch, useSelector } from 'react-redux';

import { CodeState, generateCodes } from 'src/store/slices/codes';
import {
  FetchCodesProps,
  FetchProps,
  gettingCodes,
  removeConsumerCodes,
  removeGeneratedCodes,
  setClientId,
  setConsumerCodes,
} from 'src/store/slices/codes/codes';
import { ConsumerCode } from 'src/types/code';

export const useCodes = () => {
  const dispatch = useDispatch();
  return {
    generateCodes: (fetchProps: FetchProps) => dispatch(generateCodes(fetchProps)),
    gettingCodes: (fetchProps: FetchCodesProps) => dispatch(gettingCodes(fetchProps)),
    codes: useSelector((state: { codes: CodeState }) => state.codes),
    removeGeneratedCodes: () => dispatch(removeGeneratedCodes()),
    setClientId: (clientId: string) => dispatch(setClientId(clientId)),
    setConsumerCodes: (consumers: ConsumerCode[]) => dispatch(setConsumerCodes(consumers)),
    removeConsumerCodes: () => dispatch(removeConsumerCodes()),
  };
};
