export const MIN_OF_CODES = 1;
export const MAX_OF_CODES = 1000;
export const NUM_OF_CODES_ERROR_MESSAGE = `Number of codes must be between ${MIN_OF_CODES} and ${MAX_OF_CODES}`;
export const STEP_1 = 'STEP 1';
export const STEP_2 = 'STEP 2';
export const STEP_1_TEXT = 'Let us know which leasing company this request is for.';
export const STEP_2_TEXT_CLIENT = 'Specify how many codes you need.';
export const STEP_2_TEXT_CONSUMER = 'Upload a file in .csv format with consumers’ contact details using this template.';
export const CLIENT = 'client';
export const CONSUMER = 'consumer';
export const MAX_WIDTH = 784;
