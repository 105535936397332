import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';

export const consumerIdHeader = () => (
  <span style={{ fontWeight: '500' }}>
    {'Unique Key'}
    <Tooltip title="A unique consumer identifier provided by leasing company and added in a file with consumer data. ">
      <InfoOutlinedIcon style={{ fontSize: '16px', marginLeft: '5px' }} />
    </Tooltip>
  </span>
);
