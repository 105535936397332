import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { GridTable, GridToolbar } from 'src/components/GridTable';
import { COLUMNS_CREATE_INVITATION_CODES, COLUMNS_CREATE_INVITATION_CODES_CONSUMERS } from 'src/constants/columnsOfTable';
import { routes } from 'src/constants/routes';
import { useCodes } from 'src/hooks';

export const InvitationCodesTable = (): JSX.Element => {
  const {
    codes: { generatedCodes, clientId, consumers },
  } = useCodes();
  const navigate: NavigateFunction = useNavigate();

  const onNewButtonClick = useCallback(() => {
    navigate(routes.HOME);
  }, [navigate]);

  return (
    <Box sx={{ maxWidth: consumers.length ? 988 : 600, m: '15px auto' }}>
      <Typography data-testid="table-title" component="div" align="center" sx={{ mt: 7, mb: 3, fontWeight: 'bold', fontSize: 24 }}>
        Invitation Codes
      </Typography>
      <GridTable
        rows={generatedCodes}
        columns={consumers.length ? COLUMNS_CREATE_INVITATION_CODES_CONSUMERS : COLUMNS_CREATE_INVITATION_CODES}
        components={{ Toolbar: () => <GridToolbar clientId={clientId} onNewButtonClick={onNewButtonClick} /> }}
      />
    </Box>
  );
};
