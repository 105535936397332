import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { routes } from 'src/constants/routes';
import { useEvents } from 'src/hooks';

import { useAuth } from './AuthContext';

export const AuthComponent = () => {
  const { setLoading } = useEvents();
  const [loginError, setLoginError] = useState<string>();
  const [authLoading, setAuthLoading] = useState(true);
  const { authService } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authService.isAuthenticated() && routes.LOGIN_CALLBACK !== location.pathname) {
      setLoading(true);
      void authService.login();
      return;
    }
    authService.setErrorHandler((e) => {
      setLoginError(e.message || '');
      navigate(routes.HOME);
    });
    setLoading(false);
    setAuthLoading(false);
  }, []);

  if (loginError) {
    return <Typography component="h1">{loginError}</Typography>;
  }

  if (authLoading) {
    return null;
  }

  return <Outlet />;
};
